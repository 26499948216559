<template>
  <ShareLandingCom
    v-if="videoInfo.play_url"
    :mediaInfo="mediaInfo"
    @changeVideoPlay="changeVideoPlay"
    :musicOrVideoShow="false"
    ref="ShareLandingCom"
  >
    <template slot="control">
      <div class="video-control">
        <video
          x-webkit-airplay="true"
          x5-video-player-type="h5"
          x5-video-orientation="portraint"
          x5-video-player-fullscreen="true"
          webkit-playsinline
          playsinline
          id="video"
          :poster="videoInfo.cover"
          class="video"
          loop
        >
          <source :src="videoInfo.play_url" type="video/mp4" />
        </video>

        <div @click="changePlayVideo" class="video-control-content">
          <div class="control-warp">
            <template v-if="!isVideoPlay">
              <div class="control-warp-bg"></div>
              <img v-if="!isVideoPlay" class="video-dire" src="@/assets/images/share-content/stop.png" alt="快音" />
              <img
                v-if="isVideoPlay"
                :class="['video-dire', isVideoPlay ? '' : '']"
                src="@/assets/images/share-content/start.png"
                alt="快音"
              />
            </template>
          </div>
          <div
            :data-clipboard-text="mediaInfo.invite_code"
            @click.prevent="openApp('更多-点赞-评论', '#more-like-warp')"
            id="more-like-warp"
            class="discuss-like-warp"
          >
            <div class="discuss-like-warp-item">
              <img class="right_img like_img" src="@/assets/images/share-content/like.png" alt="快音" />
              <span>{{ videoInfo.like_count }}</span>
            </div>
            <div class="discuss-like-warp-item">
              <img class="right_img" src="@/assets/images/share-content/discuss.png" alt="快音" />
              <span>{{ videoInfo.comment_count }}</span>
            </div>
            <div class="discuss-like-warp-item">更多</div>
          </div>
          <div class="aviator-focus-wrap">
            <div
              :data-clipboard-text="mediaInfo.invite_code"
              @click.prevent="openApp('关注', '#aviator-focus')"
              id="aviator-focus"
              class="aviator-focus-warp-top"
            >
              <div class="aviator-focus-warp-aviator">
                <div class="aviator-warp-bg"></div>
                <img class="aviator-warp-img__aviator" :src="videoInfo.avatar_small" alt="" />
                <img class="aviator-warp-img__add" src="@/assets/images/share-content/add.png" alt="快音" />
                <span class="aviator-warp-focus">关注</span>
              </div>
              <div v-for="(item, k) in videoInfo.tags" :key="k">
                <div class="popular-warp">
                  <div class="popular-warp-name">
                    {{ item }}
                  </div>
                  <div class="popular_bg"></div>
                </div>
              </div>
            </div>
            <div
              @click.prevent="openApp('video_name', '#video_name')"
              id="video_name"
              :data-clipboard-text="mediaInfo.invite_code"
              class="video_name"
            >
              {{ videoInfo.title }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </ShareLandingCom>
</template>

<script>
  import ShareLandingCom from './components/ShareLandingCom';
  import { mapState } from 'vuex';

  export default {
    props: {},
    components: { ShareLandingCom },
    data() {
      return {
        isVideoPlay: false,
      };
    },
    computed: {
      ...mapState('share/contentLanding', ['videoInfo', 'mediaInfo']),
    },

    async asyncData({ store, $http, params }) {
      const {
        share_user: { invite_code, uid },
        hot_music_list,
        hot_video_list,
        video: {
          cover,
          title,
          tags,
          play_url,
          counts: { comment_count, like_count },
          user_info: { avatar_url },
        },
        is_download,
      } = await $http.post('/share/video_v3', {
        ...params,
      });
      const videoInfo = {
        comment_count,
        like_count,
        cover,
        play_url,
        title,
        tags,
        avatar_small: avatar_url,
      };
      const mediaInfo = {
        uid,
        invite_code: invite_code && `邀请码【${invite_code}】`,
        hot_music_list,
        hot_video_list,
        is_download,
      };

      store.commit('share/contentLanding/SET_VIDEO_INFO', videoInfo);
      store.commit('share/contentLanding/SET_MEDIA_INFO', mediaInfo);
    },

    created() {},
    mounted() {},
    methods: {
      /**
       * @description @改变播放状态
       * @return @void
       */

      changePlayVideo() {
        const video = document.getElementById('video');

        if (video.paused) {
          this.playVideo(video);
        } else {
          this.pauseVideo(video);
        }
      },
      playVideo(video, flag = true) {
        const vidoePlayPromise = video.play();
        if (vidoePlayPromise !== undefined) {
          this.isVideoPlay = flag;
          this.$track({ page_title: '内容分享落地页', element_name: '播放视频' });
        }
      },
      pauseVideo(video) {
        if (this.isVideoPlay) {
          video.pause();
          this.isVideoPlay = false;
        }
      },
      changeVideoPlay(flag) {
        const video = document.getElementById('video');

        if (video.paused) {
          this.playVideo(video, flag);
        }
      },
      /**
       * @description 方法名
       * @param {string} element_name 元素名
       * @param {string} element_id 元素id
       */

      openApp(element_name, element_id) {
        this.$refs.ShareLandingCom.openApp(element_name, element_id);
      },
    },
  };
</script>

<style lang="less" scoped>
  .video-control {
    position: relative;
    .video {
      height: 10rem;
      width: 100%;
      object-fit: cover; /* 这里是关键 */
    }
    .video-control-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .control-warp {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .control-warp-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000;
          border-radius: 50%;
          opacity: 0.5;
        }
        .video-dire {
          z-index: 90;
          width: 1.2rem;
          height: 1.2rem;
        }
      }
      .discuss-like-warp {
        position: absolute;
        right: 0.4rem;
        bottom: 0.6rem;
        font-size: 0.24rem;
        .discuss-like-warp-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: rgb(255, 255, 255);
          margin-top: 0.2rem;
          font-size: 0.24rem;
          span {
            font-size: 0.24rem;
            text-align: center;
          }
          .like_img {
            animation: breathing 5s linear infinite;
          }
          .right_img {
            width: 0.48rem;
            height: 0.48rem;
          }
        }
      }
      .aviator-focus-wrap {
        position: absolute;
        bottom: 0.6rem;
        left: 0.3rem;
        right: 1rem;
        color: #fff;
        .aviator-focus-warp-top {
          display: flex;
          align-items: center;
          .aviator-focus-warp-aviator {
            width: 1.2rem;
            height: 0.4rem;
            display: flex;
            align-items: center;

            color: #fff;
            .aviator-warp-img__aviator {
              width: 0.32rem;
              height: 0.32rem;
              z-index: 1;
              border-radius: 50%;
              margin-left: 0.04rem;
            }
            .aviator-warp-img__add {
              width: 0.2rem;
              height: 0.2rem;
              z-index: 1;
            }
            .aviator-warp-focus {
              z-index: 1;
              font-size: 0.2rem;
            }
            .aviator-warp-bg {
              border-radius: 0.2rem;
              background: #000;
              position: absolute;
              width: 1.2rem;
              height: 0.4rem;
              opacity: 0.5;
            }
          }
          .popular-warp {
            width: 0.88rem;
            height: 0.32rem;

            font-size: 0.2rem;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.2rem;
            .popular_bg {
              background: #000;
              position: absolute;
              top: 0;
              width: 0.88rem;
              height: 0.32rem;
              opacity: 0.5;
              border-radius: 0.06rem;
            }
            .popular-warp-name {
              z-index: 1;
            }
          }
        }
        .video_name {
          font-size: 0.32rem;
          margin-top: 0.2rem;
          font-weight: 600;
        }
      }
    }

    @keyframes moveDis {
      0% {
        right: -3.4rem;
      }
      100% {
        right: 8rem;
      }
    }
    @keyframes breathing {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
</style>
